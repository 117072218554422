<!--<div class="preview-item position-{{layer.position}}">-->
<!--    <span *ngFor="let char of dataService.getChars(); index as i"-->
<!--          [class.animation1]="dataService.globalSettings.animationRunning && layer.glyphAnimationGroups[i] === 0"-->
<!--          [class.animation2]="dataService.globalSettings.animationRunning && layer.glyphAnimationGroups[i] === 1"-->
<!--          [class.animation3]="dataService.globalSettings.animationRunning && layer.glyphAnimationGroups[i] === 2"-->
<!--          [ngStyle]="{-->
<!--            'color': layer.color,-->
<!--            'color': layer.glyphColors[i],-->
<!--            'display': 'inline',-->
<!--            'font-family': layer.fontFamily,-->
<!--            'font-size': dataService.globalSettings.fontSize + 'px',-->
<!--            'line-height': dataService.globalSettings.lineHeight + 'px',-->
<!--            'letter-spacing': dataService.globalSettings.letterSpacing + 'px',-->
<!--            'z-index': layer.zIndex,-->
<!--            'font-variation-settings': '\'wdth\' ' + layer.varWdth-->
<!--          }">{{char}}</span>-->
<!--</div>-->

<div class="preview-item position-{{layer.position}}"
     [ngStyle]="{
       'font-family': layer.fontFamily,
       'z-index': layer.zIndex,
       'text-align': dataService.globalSettings.textAlignment
     }">
    <span *ngFor="let char of dataService.getChars(); index as i"
          [class.animation1]="dataService.globalSettings.animationRunning && layer.selectedConfig && layer.selectedConfig.id === 1"
          [class.animation2]="dataService.globalSettings.animationRunning && layer.selectedConfig && layer.selectedConfig.id === 2"
          [class.animation3]="dataService.globalSettings.animationRunning && layer.selectedConfig && layer.selectedConfig.id === 3"
          [class.hidden]="!layer.selectedCharIdMap[i] && char !== '\n'"
          [ngStyle]="{
            'display': char === '\n' ? 'block' : 'inline',
            'line-height': dataService.globalSettings.lineHeight + 'px',
            'letter-spacing': dataService.globalSettings.letterSpacing + 'px',
            'font-size': layer.selectedConfig?.animation.keyframes[0].fontSize + 'px',
            'color': layer.selectedConfig?.animation.keyframes[0].color,
            'font-variation-settings': '\'wdth\'' + layer.selectedConfig?.animation.keyframes[0].varWdth
          }">{{char}}</span>
</div>

<!--
old method: instead of class.hidden use ngStyle visibility to hide not selected chars
            'visibility': layer.selectedCharIdMap[i] ? 'visible' : 'hidden',
-->
