import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {LayerListComponent} from './components/layer-list/layer-list.component';
import {LayerItemComponent} from './components/layer-item/layer-item.component';
import {MenuComponent} from './components/menu/menu.component';
import {PreviewComponent} from './components/preview/preview.component';
import {PreviewLineComponent} from './components/preview-line/preview-line.component';
import {SettingsComponent} from './components/settings/settings.component';
import {AnimationSettingsComponent} from './components/animation-settings/animation-settings.component';
import {AnimationKeyframeComponent} from './components/animation-keyframe/animation-keyframe.component';
import {ConfigListComponent} from './components/config-list/config-list.component';
import {ConfigItemComponent} from './components/config-item/config-item.component';
import {OpentypeSettingsComponent} from './components/opentype-settings/opentype-settings.component';
import {AnimationButtonComponent} from './components/animation-button/animation-button.component';
import {FormsModule} from "@angular/forms";
import {ColorPickerModule} from "ngx-color-picker";
import {CommonModule} from "@angular/common";

@NgModule({
  declarations: [
    AppComponent,
    LayerListComponent,
    LayerItemComponent,
    MenuComponent,
    PreviewComponent,
    PreviewLineComponent,
    SettingsComponent,
    AnimationSettingsComponent,
    AnimationKeyframeComponent,
    ConfigListComponent,
    ConfigItemComponent,
    OpentypeSettingsComponent,
    AnimationButtonComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ColorPickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
