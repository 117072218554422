import {Injectable} from '@angular/core';
import {Layer} from "../datatypes/layer";
import {Animation} from "../datatypes/animation";
import {AnimationKeyframe} from "../datatypes/animation-keyframe";
import {GlobalSettings} from "../datatypes/global-settings";
import {Config} from "../datatypes/config";
import {OpentypeSettings} from "../datatypes/opentype-settings";

@Injectable({
  providedIn: 'root',
})
export class DataService {
  layers: Layer[] = [];
  configs: Config[] = [];
  fontFamilies: string[] = [];
  globalSettings: GlobalSettings;

  init() {
    this.globalSettings = new GlobalSettings();

    this.addConfig();
    this.addLayer();

    this.fontFamilies.push('roboleVar1');
    this.fontFamilies.push('roboleVar2');
    this.fontFamilies.push('roboleVar3');
    this.fontFamilies.push('roboleVar4');
    this.fontFamilies.push('roboleVar5');
    this.fontFamilies.push('roboleVar6');
    this.fontFamilies.push('roboleVar7');
    this.fontFamilies.push('roboleVar8');
    this.fontFamilies.push('roboleVar9');
    this.fontFamilies.push('Plex');
  }

  addLayer() {
    const layerId = this.layers.length + 1;
    this.layers.push(new Layer(
      layerId,
      'roboleVar1',
      1,
      0,
      this.configs[0]
    ));

    // console.log(this.layers);
  }

  addConfig() {
    const configId = this.configs.length + 1;

    const keyframes = [
      this.initDefaultAnimationKeyframe(configId),
      new AnimationKeyframe(
        2,
        50,
        1000,
        180,
        '#fa71aa'
      ),
      new AnimationKeyframe(
        3,
        100,
        0,
        180,
        '#860086'
      )
    ];

    this.configs.push(new Config(
      configId,
      new OpentypeSettings(),
      new Animation(
        'ease-in',
        2,
        0,
        1000,
        keyframes
      )
    ));

    // console.log(this.configs);
  }

  addKeyframe(config: Config) {
    config.animation.keyframes.push(this.initDefaultAnimationKeyframe(config.id));
  }

  initDefaultAnimationKeyframe(configId: number) {
    const config = this.configs.find(el => el.id === configId);
    const keyframeId = config ? config.animation.keyframes.length + 1 : 1;
    return new AnimationKeyframe(
      keyframeId,
      0,
      0,
      180,
      '#860086'
    );
  }

  generateAnimationCss() {
    let css = '';
    for (let i = 0; i < this.configs.length; i++) {
      css += `
  .animation` + (i + 1) + ` {
    animation-name: a` + (i + 1) + `;
    animation-duration: ` + this.configs[i].animation.duration + `s;
    animation-delay: ` + this.configs[i].animation.delay + `s;
    animation-iteration-count: ` + this.configs[i].animation.iterationCount + `;
    animation-timing-function: ` + this.configs[i].animation.easing + `;
  }

  @keyframes a` + (i + 1) + ` {
  `;

      this.configs[i].animation.keyframes.forEach((keyframe) => {
        css += keyframe.position + `% {
        font-size: ` + keyframe.fontSize + `px;
        font-variation-settings: \'wdth\' ` + keyframe.varWdth + `;
        color: ` + keyframe.color + `;
      }
      `
      });
      css += `}`;
    }

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }

  generatePreviewPositionCss() {
    let css = '';
    for (let i = -20; i <= 20; i++) {
      css += `
  .position-` + i+ ` {
    margin-top: ` + (i * this.globalSettings.lineHeight) + `px;
  } `;
    }

    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
  }

  removeLayer(layer: Layer) {
    this.layers = this.layers.filter(l => l !== layer);
  }

  removeConfig(config: Config) {
    this.configs = this.configs.filter(c => c !== config);
  }

  removeKeyframe(configId: number, keyframe: AnimationKeyframe) {
    let config = this.configs.find(el => el.id === configId);
    const configIndex = this.configs.findIndex(el => el.id === configId);
    config.animation.keyframes = config.animation.keyframes.filter(k => k !== keyframe);
    this.configs[configIndex] = config;
  }

  getChars(): string[] {
    let chars = [];
    for (let i = 0; i < this.globalSettings.text.length; i++) {
      chars.push(this.globalSettings.text[i]);
    }
    return chars;
  }
}
