import { Component, OnInit } from '@angular/core';
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-animation-button',
  templateUrl: './animation-button.component.html',
  styleUrls: ['./animation-button.component.scss']
})
export class AnimationButtonComponent implements OnInit {

  constructor(public dataService: DataService) {}

  ngOnInit(): void {}

  onAnimationToggle(): void {
    // TODO refactor maybe?
    this.dataService.generateAnimationCss();

    this.dataService.globalSettings.animationRunning = !this.dataService.globalSettings.animationRunning;
  }
}
