<h1>Global settings</h1>

<div class="global-settings-wrapper">
  <label class="label__textarea">Text
    <textarea [value]="dataService.globalSettings.text"
              (input)="dataService.globalSettings.text = $event.target.value"></textarea>
  </label>

  <label>Spacing
    <input type="number"
           [value]="dataService.globalSettings.letterSpacing"
           (input)="dataService.globalSettings.letterSpacing = $event.target.value">
  </label>

  <!--  <label>Font Size-->
  <!--    <input type="number"-->
  <!--           [value]="dataService.globalSettings.fontSize"-->
  <!--           (input)="dataService.globalSettings.fontSize = $event.target.value">-->
  <!--  </label>-->

  <label>Line Height
    <input type="number"
           [value]="dataService.globalSettings.lineHeight"
           (input)="dataService.globalSettings.lineHeight = $event.target.value"
           (change)="onChangeLineHeight()">
  </label>

  <label>Background Color
    <input class="input-color"
           type="text"
           [value]="dataService.globalSettings.bgColor"
           [style.background]="dataService.globalSettings.bgColor"
           [(colorPicker)]="dataService.globalSettings.bgColor"/>
  </label>

  <div class="text-alignment">
    <label>Text alignment</label>
    <div class="text-alignment__option">
      <input class="radio--text-alignment"
             type="radio"
             id="text-alignment-left"
             name="text-alignment"
             value="left"
             [(ngModel)]="this.dataService.globalSettings.textAlignment"
             (change)="this.dataService.globalSettings.textAlignment = $event.target.value"/>
      <label for="text-alignment-left" class="icon">
        <i class="fas fa-align-left"></i>
      </label>
    </div>
    <div class="text-alignment__option">
      <input class="radio--text-alignment"
             type="radio"
             id="text-alignment-center"
             name="text-alignment"
             value="center"
             [(ngModel)]="this.dataService.globalSettings.textAlignment"
             (change)="this.dataService.globalSettings.textAlignment = $event.target.value"/>
      <label for="text-alignment-center" class="icon">
        <i class="fas fa-align-center"></i>
      </label>
    </div>
    <div class="text-alignment__option">
      <input class="radio--text-alignment"
             type="radio"
             id="text-alignment-right"
             name="text-alignment"
             value="right"
             [(ngModel)]="this.dataService.globalSettings.textAlignment"
             (change)="this.dataService.globalSettings.textAlignment = $event.target.value"/>
      <label for="text-alignment-right" class="icon">
        <i class="fas fa-align-right"></i>
      </label>
    </div>
  </div>
</div>
