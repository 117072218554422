import {Component, Input, OnInit} from '@angular/core';
import {Layer} from "../../datatypes/layer";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-preview-line',
  templateUrl: './preview-line.component.html',
  styleUrls: ['./preview-line.component.scss']
})
export class PreviewLineComponent implements OnInit {
  @Input() layer: Layer;

  constructor(public dataService: DataService) {

  }

  ngOnInit(): void {
  }

}
