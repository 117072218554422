<div class="accordion"
     [class.active]="accordionOpen">
  <div class="accordion__header"
       (click)="accordionOpen = !accordionOpen">
    Layer {{layer.id}}
    <a *ngIf="layer.id !== 1"
       class="btn__remove"
       (click)="dataService.removeLayer(layer)">
      <i class="far fa-minus-square"></i>
    </a>
  </div>
  <div class="accordion__content">
    <div class="layer-settings">
      <label class="styled-select">
        Font family
        <select name="select-font-family"
                (change)="layer.fontFamily = $event.target.value">
          <option *ngFor="let family of dataService.fontFamilies"
                  [value]="family">{{family}}</option>
        </select>
      </label>

      <!--      <label>Var Wdth-->
      <!--        <input type="number"-->
      <!--               [value]="layer.varWdth"-->
      <!--               (input)="layer.varWdth = $event.target.value">-->
      <!--      </label>-->

      <label>Z-index
        <input type="number"
               [value]="layer.zIndex"
               (input)="layer.zIndex = $event.target.value">
      </label>

      <label class="styled-select">
        Config
        <select [(ngModel)]="layer.selectedConfig">
          <option value="null">Select config</option>
          <option *ngFor="let config of dataService.configs"
                  [ngValue]="config">Config {{config.id}}</option>
        </select>
      </label>

      <label>Position
        <input type="number"
               min="-20"
               max="20"
               [value]="layer.position"
               (input)="layer.position = $event.target.value">
      </label>
    </div>

    <!--<label>-->
    <!--  <span class="color-label">Color</span>-->
    <!--  <span style="background-color: #ffffff;" data-hex="#ffffff" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #131313;" data-hex="#131313" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #000044;" data-hex="#000044" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #3B3B3B;" data-hex="#3B3B3B" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #b33640;" data-hex="#b33640" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #fe5858;" data-hex="#fe5858" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #666fb9;" data-hex="#666fb9" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--  <span style="background-color: #84D7DC;" data-hex="#84D7DC" class="color"-->
    <!--        (click)="layer.color = $event.target.getAttribute('data-hex')"></span>-->
    <!--</label>-->

    <!--<div class="glyph-colors">-->
    <!--  <div *ngFor="let char of dataService.getChars(); index as i">-->
    <!--    <strong>{{char}}</strong>-->
    <!--    <span style="background-color: #ffffff;" data-hex="#ffffff" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #131313;" data-hex="#131313" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #000044;" data-hex="#000044" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #3B3B3B;" data-hex="#3B3B3B" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #b33640;" data-hex="#b33640" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #fe5858;" data-hex="#fe5858" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #666fb9;" data-hex="#666fb9" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--    <span style="background-color: #84D7DC;" data-hex="#84D7DC" class="glyph-color"-->
    <!--          (click)="layer.glyphColors[i] = $event.target.getAttribute('data-hex')"></span>-->
    <!--  </div>-->
    <!--</div>-->

    <!--<div class="glyph-groups">-->
    <!--  <div *ngFor="let char of dataService.getChars(); index as i">-->
    <!--    <div class="glyph-group">-->
    <!--        <span class="glyph-group__item" [class.active]="layer.glyphAnimationGroups[i] === iInner"-->
    <!--              *ngFor="let charInner of dataService.getChars(); index as iInner"-->
    <!--              (click)="layer.glyphAnimationGroups[i] = iInner">-->
    <!--      {{ iInner + 1 }}</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</div>-->

    <span class="label">Select chars</span>
    <ul class="selected-chars">
      <li *ngFor="let char of dataService.getChars(); index as i"
          [class.selected]="layer.selectedCharIdMap[i]"
          [class.line-break]="char === '\n'"
          (click)="onCharClick(i)">{{char}}</li>
    </ul>
  </div>
</div>
