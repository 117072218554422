<h1>OpenType Settings</h1>
<p><em>--TODO--</em><br><br></p>
<!--<label class="styled-checkbox">-->
<!--  <input type="checkbox">-->
<!--  <span class="checkmark"></span>-->
<!--  <span class="checkbox-text">Proportional Numbers</span>-->
<!--</label>-->
<!--<label class="styled-checkbox">-->
<!--  <input type="checkbox">-->
<!--  <span class="checkmark"></span>-->
<!--  <span class="checkbox-text">Alternate y</span>-->
<!--</label>-->
