import {AnimationKeyframe} from "./animation-keyframe";

export class Animation {
  easing: string;
  duration: number;
  delay: number;
  iterationCount: number;
  keyframes: AnimationKeyframe[];

  constructor(easing: string, duration: number, delay: number, iterationCount: number, keyframes: AnimationKeyframe[]) {
    this.easing = easing;
    this.duration = duration;
    this.delay = delay;
    this.iterationCount = iterationCount;
    this.keyframes = keyframes;
  }
}

