import {Component, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public dataService: DataService) {
  }

  ngOnInit(): void {
    this.dataService.generatePreviewPositionCss();
  }

  onChangeLineHeight() {
    this.dataService.generatePreviewPositionCss();
  }

}
