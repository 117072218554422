<strong>Keyframe</strong>

<a *ngIf="keyframe.id !== 1"
   class="btn__remove-keyframe"
   (click)="dataService.removeKeyframe(configId, keyframe)">
  <i class="far fa-minus-square"></i>
</a>

<label class="full-width">Position (0-100)
  <input type="number"
         [value]="keyframe.position"
         min="1"
         max="100"
         (input)="keyframe.position = $event.target.value"/>
</label><br>

<label>VarWdth (0-1000)
  <input type="number"
         min="0"
         max="1000"
         [value]="keyframe.varWdth"
         (input)="keyframe.varWdth = $event.target.value"/>
</label>
<div class="apply-buttons">
  <button (click)="onClickAdoptToEachKeyframeInThisConfig('varWdth')">Apply to config</button>
  <button (click)="onClickAdoptToEachKeyframeInAllConfigs('varWdth')">Apply everywhere</button>
</div>

<label>Font size
  <input type="number"
         min="1"
         [value]="keyframe.fontSize"
         (input)="keyframe.fontSize = $event.target.value"/>
</label>
<div class="apply-buttons">
  <button (click)="onClickAdoptToEachKeyframeInThisConfig('fontSize')">Apply to config</button>
  <button (click)="onClickAdoptToEachKeyframeInAllConfigs('fontSize')">Apply everywhere</button>
</div>

<label>Color
  <input class="input-color"
         type="text"
         [value]="keyframe.color"
         [style.background]="keyframe.color"
         [(colorPicker)]="keyframe.color"/>
</label>
<div class="apply-buttons">
  <button (click)="onClickAdoptToEachKeyframeInThisConfig('color')">Apply to config</button>
  <button (click)="onClickAdoptToEachKeyframeInAllConfigs('color')">Apply everywhere</button>
</div>
