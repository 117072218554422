import {AnimationProperty} from "./animation-property";

export class AnimationKeyframe {
  id: number;
  position: number;
  varWdth: number;
  fontSize: number;
  color: string;
  // properties: AnimationProperty[];

  constructor(id: number, position: number, varWdth: number, fontSize: number, color: string) {
    this.id = id;
    this.position = position;
    this.varWdth = varWdth;
    this.fontSize = fontSize;
    this.color = color;
  }
}
