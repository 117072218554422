import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Layer} from "../../datatypes/layer";

@Component({
  selector: 'app-layer-item',
  templateUrl: './layer-item.component.html',
  styleUrls: ['./layer-item.component.scss']
})
export class LayerItemComponent implements OnInit {
  @Input() layer: Layer;
  accordionOpen = true;

  constructor(public dataService: DataService) {
  }

  ngOnInit(): void {
    if (this.layer.id === 1) {
      if (this.dataService.configs[0].animation) {
        this.layer.selectedConfig = this.dataService.configs[0];
      }
    } else {
      this.accordionOpen = false;
    }
  }

  onSelectConfig($event) {
    this.layer.selectedConfig = $event.target.value
  }

  onCharClick(charIndex: number) {
    this.layer.selectedCharIdMap[charIndex] = !this.layer.selectedCharIdMap[charIndex];
    console.log(this.dataService.globalSettings);
  }

}
