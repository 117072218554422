import {Component, Input, OnInit} from '@angular/core';
import {Config} from "../../datatypes/config";
import {DataService} from "../../services/data.service";

@Component({
  selector: 'app-config-item',
  templateUrl: './config-item.component.html',
  styleUrls: ['./config-item.component.scss']
})
export class ConfigItemComponent implements OnInit {
  @Input() config: Config;
  accordionOpen = true;

  constructor(public dataService: DataService) {

  }

  ngOnInit(): void {
    this.accordionOpen = false;
  }
}
