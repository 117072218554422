import {Component, Input, OnInit} from '@angular/core';
import {AnimationKeyframe} from "../../datatypes/animation-keyframe";
import {DataService} from "../../services/data.service";
import {switchMapTo} from "rxjs/operators";

@Component({
  selector: 'app-animation-keyframe',
  templateUrl: './animation-keyframe.component.html',
  styleUrls: ['./animation-keyframe.component.scss']
})
export class AnimationKeyframeComponent implements OnInit {
  @Input() keyframe: AnimationKeyframe;
  @Input() configId: number;

  constructor(public dataService: DataService) {

  }

  ngOnInit(): void {
  }

  onClickAdoptToEachKeyframeInThisConfig(optionName: string) {
    const i = this.dataService.configs.findIndex(el => el.id === this.configId);
    this.dataService.configs[i].animation.keyframes.forEach(el => {
      this.applyOptionValueToKeyframe(el, optionName);
    });
  }

  onClickAdoptToEachKeyframeInAllConfigs(optionName: string) {
    this.dataService.configs.forEach(config => {
      config.animation.keyframes.forEach(el => {
        this.applyOptionValueToKeyframe(el, optionName);
      });
    });
  }

  private applyOptionValueToKeyframe(keyframe: AnimationKeyframe, optionName) {
    switch(optionName) {
      case 'fontSize':
        keyframe.fontSize = this.keyframe.fontSize;
        break;
      case 'varWdth':
        keyframe.varWdth = this.keyframe.varWdth;
        break;
      case 'color':
        keyframe.color = this.keyframe.color;
        break;
      default:
        break;
    }
  }

}
