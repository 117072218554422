import {Config} from "./config";

export class Layer {
  id: number;
  fontFamily: string;
  // varWdth: number;
  zIndex: number;
  position: number;
  // color: string;
  // glyphColors: string[];
  selectedConfig: Config;
  selectedCharIdMap: boolean[];

  /**
   * defines the group a glyph is related to
   * e.g. first glyph is related to group 4
   * => glyphGroups[0] = 4;
   */
  // glyphAnimationGroups: number[];

  constructor(id: number, fontFamily: string, zIndex: number, position = 0, selectedConfig = null) {
    this.id = id;
    this.fontFamily = fontFamily;
    // this.varWdth = varWdth;
    this.zIndex = zIndex;
    this.position = position;
    // this.color = color;
    // this.glyphColors = [];
    // this.glyphAnimationGroups = [];
    this.selectedConfig = selectedConfig;
    this.selectedCharIdMap = [true];
  }
}
