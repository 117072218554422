<app-animation-keyframe *ngFor="let keyframe of animation.keyframes" class="animation-keyframe"
                        [keyframe]="keyframe"
[configId]="config.id"></app-animation-keyframe>

  <a href="#" class="btn btn__add-keyframe" (click)="dataService.addKeyframe(config)">
    <i class="far fa-plus-square"></i>Add keyframe</a>

<div class="config-options">
  <label class="styled-select">
    Easing
    <select name="select-font-family"
            (change)="animation.easing = $event.target.value">
      <option value="linear">linear</option>
      <option value="ease-in">ease-in</option>
      <option value="ease-out">ease-out</option>
    </select>
  </label>

  <label>Duration (s)
    <input type="number"
           [value]="animation.duration"
           (input)="animation.duration = $event.target.value">
  </label>

  <label>Delay (s)
    <input type="number"
           [value]="animation.delay"
           (input)="animation.delay = $event.target.value">
  </label>

  <label>Iteration count
    <input type="number"
           [value]="animation.iterationCount"
           (input)="animation.iterationCount = $event.target.value">
  </label>
</div>
