import {Component, Input, OnInit} from '@angular/core';
import {OpentypeSettings} from "../../datatypes/opentype-settings";

@Component({
  selector: 'app-opentype-settings',
  templateUrl: './opentype-settings.component.html',
  styleUrls: ['./opentype-settings.component.scss']
})
export class OpentypeSettingsComponent implements OnInit {
  @Input() opentypeSettings: OpentypeSettings;

  constructor() { }

  ngOnInit(): void {
  }

}
