import {OpentypeSettings} from "./opentype-settings";
import {Animation} from "./animation";

export class Config {
  id: number;
  openTypeSettings: OpentypeSettings;
  animation: Animation;

  constructor(id: number, openTypeSettings: OpentypeSettings, animation: Animation) {
    this.id = id;
    this.openTypeSettings = openTypeSettings;
    this.animation = animation;
  }
}
