<div class="accordion"
     [class.active]="accordionOpen">
  <div class="accordion__header"
       (click)="accordionOpen = !accordionOpen">
    Config {{config.id}}
    <a *ngIf="config.id !== 1"
       class="btn__remove"
       (click)="dataService.removeConfig(config)">
      <i class="far fa-minus-square"></i>
    </a>
  </div>
  <div class="accordion__content">
    <app-opentype-settings class="opentype-settings" [opentypeSettings]="config.openTypeSettings"></app-opentype-settings>
    <app-animation-settings class="animation-settings" [animation]="config.animation" [config]="config"></app-animation-settings>
  </div>
</div>

