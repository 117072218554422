import {Component, Input, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Animation} from "../../datatypes/animation";
import {Config} from "../../datatypes/config";

@Component({
  selector: 'app-animation-settings',
  templateUrl: './animation-settings.component.html',
  styleUrls: ['./animation-settings.component.scss']
})
export class AnimationSettingsComponent implements OnInit {
  @Input() animation: Animation;
  @Input() config: Config;

  constructor(public dataService: DataService) {

  }

  ngOnInit(): void {
  }

}
